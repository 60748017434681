/**
 * Environment Configuration
 */
export const CONFIG_NODE_ENV = "stg"
export const CONFIG_APP_SITE = "https://webstg.wawanesa.com"
export const CONFIG_CAPTCHA_PUBLIC = "6Lca9bQUAAAAADU2dEUZPocWu5jn_6pnH"
export const CONFIG_GOOGLE_API_KEY = "AIzaSyAbQQcdout-njd_-mn1kA-Sp8cv39qyqFY"
export const CONFIG_GTM_TAG_ID = "GTM-T95W5F"
export const CONFIG_BROKER_DATA = "https://stg-api.fab-nonprod.wawanesa.com/web/canada/api/brokers"
export const CONFIG_NAVIGATION = "https://stg-api.fab-nonprod.wawanesa.com/web/canada/api/nav"
export const CONFIG_LIFE_ENDPOINT = "https://stg.wawanesalife.com"

